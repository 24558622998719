//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LoadingTemplate',
  beforeCreate() {
    this.$vuetify.theme.light = false
    this.$vuetify.theme.dark = true
  }
}
